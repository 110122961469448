footer {
    background-color: #040b18;
    height : 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}footer h2{
    display: inline-block;
    color: indianred;
    font-family: 'Poller One', arial;
    animation: slide 5s infinite;
    transform: translateX(150%);
}

@keyframes slide{
    30%{ transform: translate(0); }
    70%{ transform: translate(0); }
    100%{ transform: translateX(-150%); }
}


@media (max-width: 900px) {
    footer h2{
        display: flex;
        flex-direction: column;
        font-size: 1.5rem;
        transform: unset;
        animation: unset;
    }footer h2 span{
        margin-bottom: 15px;
        text-align: right;
        transform: unset;
    }
    #footer-span1{
        animation: slide1 5s infinite;
    }
    #footer-span2{
        animation: slide2 5s infinite;
    }
    #footer-span3{
        animation: slide3 5s infinite;
    }
    #footer-span4{
        animation: slide4 5s infinite;
    }

    @keyframes slide1{
        0%{ transform: translateX(-120%); }
        40%, 60%{ transform: translateX(0); }
        100%{ transform: translateX(120%); }
    } @keyframes slide2{
        0%{ transform: translateX(-120%); }
        50%, 60%{ transform: translateX(0); }
        100%{ transform: translateX(120%); }
    } @keyframes slide3{
        0%{ transform: translateX(-120%); }
        60%{ transform: translateX(0); }
        100%{ transform: translateX(120%); }
    } @keyframes slide4{
        0%{ transform: translateX(-120%); }
        60%{ transform: translateX(0); }
        100%{ transform: translateX(120%); }
    }
}
  