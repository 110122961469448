/* @import url('https://fonts.googleapis.com/css2?family=Teko:wght@300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poller+One&family=Teko:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

@import url('https://css.gg/chevron-right.css');
@import url('https://css.gg/sun.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  scroll-behavior: smooth;
  
}
html, body {
  background-color : rgb(4, 11, 24);
  color: aliceblue;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 900px){
  html{
      height: 100%;
      overflow-y: scroll;
      scroll-snap-type: y mandatory;
      margin: 0;
      padding: 0;
  }
  #root  > *{
      scroll-snap-align: start;
      min-height: 100vh;
  }
  #header{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  }#identity{
      margin: auto 0;
  }#identity-center{
      margin-top: 0;
  }#presentation{
    scroll-snap-align: none;
  }
  #presentation-text, #presentation-photo{
      min-height: 100vh;
      margin: auto 0;
      width : auto;
  }
  #project{
    padding-top: 0;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }#project h1 {
    font-size: 2rem;
    margin: 30px 0;
  }

  
}
