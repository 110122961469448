#project{
    background: #0d314a;
    padding: 70px;
    padding-top: 100px;
    
    font-family: 'Lato', sans-serif;
}#project h1{
    font-family: 'Poller One', cursive;
    text-align: center;
    font-size: 2.5rem;
    margin: 0;
}#project h2{
    font-size: 1.5rem;
    font-weight: 900;
    padding: 10px;
    /* transition: background-color 0.3s ease-in-out; */
}.project-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
}.project-title:hover{
    /* background-color: rgba(135, 206, 235, 0.7); */
    /* background-color: rgba(64, 224, 208, 0.7); */
    background-color: rgba(255, 165, 0, 0.5); 
    
    /* background-color: #ffe74280;  */
}#project .project-class-arrow{
    display: inline-block;
    margin-right: 20px;
    transition: transform 0.3s ease-in-out;
    transform-origin: 50% 55%;
}#project .project-class-arrow > *{
    padding-top: 5px;
    transform: translateY(3px);
}#project > p{
    text-align: center;
    font-size: 1rem;
}


#project-description{
    margin-bottom: 70px;
}.project-object-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; 
    align-items: center;
    transition: 
        opacity 0.5s ease-in-out,
        height 0.3s ease-in-out;
}
.project-object{
    background-color: #ffffff;
    width: 95%;
    min-height: 200px;
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 3px 15px rgba(135, 206, 235, 0.4);
    transition: all 0.3s ease-in-out;
}.project-object-image{
    height: 200px;
    width: 200px;
    min-width: 200px;
    border-right: 2px solid grey;
}.project-object-image img{
    display : block;
    max-width: 100%; 
    height: 200px;
    object-fit: cover;
}.project-object-letter{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0d314a;
    font-size: 90px;
    height: 200px;
    width: 200px;
}.project-object-right{
    padding-left: 20px;
    padding-top: 10px;
    width: 100%;
    position: relative;
    padding: 15px 20px;
}
.project-object-title{
    display: flex;
    text-align: start;
    justify-content: space-between;
}.project-object-title h3{
    margin: 1px;
    color : #2c75ff;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 25px;
}.project-object-description{
    color: black;
    height: 100px; 
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}.project-object-description p{  
    padding-right: 20px;
    text-align: start;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 100px;
    font-weight: 300;
    transition: all 0.3s ease-in-out;
    line-height: 20px;
}.project-link{
    color: blue;
    text-decoration: underline;
    font-weight: 400;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}.project-object-links{
    /* position: absolute; */
    display: flex;
    justify-content: flex-end;
    width: 110px;
    /* right : 10px;
    bottom: 10px; */
}.project-object-skills{
    display: flex;
    flex-wrap: wrap;
}.project-object-skills span{
    padding: 0 7px;
    line-height: 20px;
    display: inline-block;
    border : 1.5px solid rgb(109, 109, 109);
    color: #0d314a;
    border-radius : 2em;
    background-color : #ffffff;
    max-width: auto;
    min-width: 30px;
    max-height: 25px;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
}.project-object-skills span:not(.skills-last){
    margin-right: 5px;
}

.mobile-project-page-title{
    padding: 20px 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #2e6a9e;
}.mobile-project-page-title a{
    color: black; 
}.mobile-project-page-title svg{
    width: 30px;
    height: 30px;
}.mobile-project-page-title h1{
    font-size: 1.4rem;
    margin : 0 auto;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: white
}.mobile-project-object-container{
    background-color: #0d314a;
    padding: 20px;
    padding-top: 100px;
}.mobile-project-object-container .project-object{
    margin-right: auto; 
    margin-left: auto;
    margin-bottom: 40px; 
}

@keyframes extend{
    0%{
        height: 0px;
    }
    100%{
        height: 100px;
    }
}

@media (max-width: 900px) {
    .project-object{
        min-width: 300px;
        min-height: 510px;
        flex-direction: column;
        border-radius: 0.7em;
        
    }.project-object-image{
        width: 100%;
        min-width: 200px;
        border: none;
    }.project-object-image img, 
    .project-object-letter{
        margin: auto;
    }.project-object-right{
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
        width: 90%;
        position: relative;
        margin: 0 auto;
        margin-top: 20px;
    }
    .project-object-title{
        text-align: center;
        flex-direction: column-reverse;
        justify-content: space-between;
    }.project-object-title h3{
        margin: 1px;
        color : #2c75ff;
        font-weight: 700;
    }.project-object-description{
        color: black;
        height: 200px; 
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }.project-object-description p{  
        padding-right: 0px;
        text-align: start;
        margin: 20px auto;
    }.project-object-skills{
        justify-content: flex-end;
        margin-bottom: 16px;
    }.project-title{
        display: block;
        margin-bottom: 0px;
    }.project-title a{
        color: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0px;
        cursor: pointer;
    }.project-title a:hover{
        background-color: rgba(255, 165, 0, 0); 
    }
}

@media (max-width: 500px) {
    #project{
        padding: 20px;
        padding-top: 50px;
    }#project h1{
        font-size: 2rem;
        margin-bottom: 30px;
    }#project h2{
        font-size: 1rem;
        font-weight: 900;
        padding: 10px;
        /* transition: background-color 0.3s ease-in-out; */
    }.project-object img{
        border-top-left-radius: 0.7em;
        border-top-right-radius: 0.7em;
    }
}