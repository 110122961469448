nav, nav ul{
    display: flex;
    align-items: center;
    justify-content:space-between;
}

a{
    text-decoration: none;
}

nav{
    border-bottom: 5px solid rgb(44, 117, 255);
    border-bottom-right-radius: 20px;
    width: 100%;
} nav li{
    list-style-type : none;
    margin-left: 15px;
} nav ul{
    margin-right: 40px; ;
}nav a{
    color: aliceblue
}nav a:hover{
    color: rgb(44, 117, 255);
}

#pp{
    margin-left: 40px;
    font-size: 14px;
    font-family: 'Teko', sans-serif;
}#pp img{
    width: 50px;
}


#identity{
    max-height: 500px;
    display: flex;
    justify-content : center;
    align-items:flex-start;
} #identity-center{
    margin-top : 90px;
    text-align : center;
    font-family: 'Poller One', cursive;
    color: #ffe742;
    font-size : 3rem;
    
    text-transform: uppercase;
    width: 750px;
    
} #identity-center > *{
    display: flex;
    justify-content :center;
    align-items: center;
    margin: auto;
    cursor: pointer;
} #identity-name {
    margin: 0;
    height : 100px;
}
#identity-fname{
    height : 100px;
    margin: auto;
    background-color: #ffe742;
    color : rgb(4, 11, 24);
} #identity-title{
    height: 100px;
    margin:auto;
} #identity-title > span {
    align-self: center;
    text-overflow: clip;
}.change{
    cursor: pointer;
}
#change-identity{
    background-color: inherit; 
    border: 0;
    color:#ffe742;
    cursor: pointer;
}#change-identity svg{
    animation:  rotation 3s infinite;
}.dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
}.dropdown-content {
    display: none;
    position: absolute;
    background-color: #0189eae5;
    min-width: 140px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    right: -10px;
    top: 24px;
    border-radius: 0.4em;
    padding: 10px 0;
}.dropdown-content li {
    color: aliceblue;
    text-align: end;
    text-decoration: none;
    display: block;
    padding: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}.dropdown-content li:hover {
    background-color: #f1f1f1;
}.dropdown:hover .dropdown-content {
    display: block;
}

@keyframes rotation{
    0%{
        transform: rotate(0);
    }100%{
        transform: rotate(360deg)
    }
}

@media (max-width: 500px){
    #identity{
        max-height: 500px;
    } #identity-center{
        font-size : 2rem;
        
        width: 750px;
        
    }
}