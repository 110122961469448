#presentation:before{
    content: ' ';
    opacity: 0.1;
    background-repeat: repeat-y;
    background-size: contain;
    width: 100%;
    height: 130%;
    position: absolute;
}
#presentation{
    display: flex;
    justify-content : center;
    align-items:center;
    flex-wrap: wrap;
    padding: 70px;
    background-color: rgb(4, 11, 24);
}.presentation-container{
    z-index: 1;
    background-color: rgb(4, 11, 24);
    color: rgb(144, 144, 169);

    height: 450px;
    width: 450px;
    margin : auto;

    display: flex;
    flex-direction: column;
    justify-content : flex-start;
    align-items: center;
}

#presentation-size{
    font-family: 'Poller one', arial;
    text-align: center;
    font-size: 0.6rem;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    width: 300px;
}#presentation-size > span{
    margin-bottom: 25px;
}#presentation-size button {
    font-size: 100%;
    font-family: inherit;
    border: 0;
    padding: 0;
    border-radius: 100%;
    background-color: transparent;
}#presentation-size button::after{
    content: '';
    display: block;
    height: 15px;
    width: 15px;
    background-color: transparent;
    border-radius: 100%;
    border: 1px solid rgb(44, 117, 255);
    cursor: pointer;
}#lenght-container{
    display: flex;
    flex-direction: column;
}#lenght-button-container, #shortest-longest{
    display: flex;
    justify-content: space-between;
}#lenght-button-container{
    margin-bottom: 10px;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap');
#presentation-bio{
    cursor: pointer;
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}#what-to-know, #connect-with{
    font-size: 1.5em;
    line-height: 20px;
    height: 20px;
    margin-bottom: 20px;
}

#presentation-connect{
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    font-family: 'Poller One', cursive;
    font-size: 0.7rem;
}#bubble-link-container{
    display: flex;
    justify-content: center;
}.bubble-link{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid white;
    background-color: white;
    margin-right: 10px;
    margin-left: 10px;
}.bubble-link img{
    display: block;
    border-radius: 100%;
    width: 100%;
    margin: auto;
    transform: scale(1.1)
}
#presentation-text, #presentation-photo{
    display: flex;
    flex-direction: column;
    justify-content: center;
}#portrait {
    height: auto;
    width: 350px;
    margin-bottom: 50px;
}
#portrait img{
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;

    display : block;
    margin: auto;
    max-width: 100%;
    
    height: auto;
    max-height: 300px;
}#portrait span{
    display: inline-block;
    width: 100%;
    margin-top: 15px;
    text-align: center;
}#portrait-minus {
    display: flex;
    justify-content: space-between;
    width: 350px;
}#portrait-minus > *{
    height: 50px;
    width: 50px;
    cursor: pointer;
    object-fit: cover;
}

@media (max-width: 900px){
    #presentation-text, #presentation-photo{
        margin : auto !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }#presentation-text p{
        padding : 0 20px;
        text-align: center;
    }#portrait-minus{
        justify-content: space-around;
    }#portrait{
        max-width: 350px;
    }#portrait img{
        padding: 0;
        max-width: 350px;
    }

    #presentation-bio{
        margin-top: 30px;
        margin-bottom: 50px;
    }
}