.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    min-height: unset !important;
}.scroll-to-top button {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 24px;
    height: 25px;
    width: 25px;
    padding: 0;
    transition: background-color 0.3s ease;
}.scroll-to-top button:hover {
    background-color: #1a4bbf;  
}